import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumEmptyPageModule, CelumIconModule, CelumLoadingMaskModule } from '@celum/common-components';
import { AssetFilter, LibraryError } from '@celum/libraries/domain';
import { CelumDirectivesModule, CelumPipesModule, TranslationModule } from '@celum/ng2base';
import { CelumChipComponent, SearchAndSelectComponent } from '@celum/shared/ui';

import { AssetsFilterService } from './assets-filter.service';

@Component({
  imports: [
    CommonModule,
    OverlayModule,
    TranslateModule,

    MatChipsModule,
    MatTooltipModule,

    CelumChipComponent,
    CelumDirectivesModule,
    CelumEmptyPageModule,
    CelumIconModule,
    CelumLoadingMaskModule,
    CelumPipesModule,
    TranslationModule,

    SearchAndSelectComponent
  ],
  selector: 'libs-assets-filter',
  templateUrl: './assets-filter.component.html',
  styleUrls: ['./assets-filter.component.scss'],
  providers: [
    AssetsFilterService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LibraryAssetsFilterComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LibraryAssetsFilterComponent implements ControlValueAccessor {
  @HostBinding('class.libs-assets-filter') public hostCls = true;

  constructor(protected service: AssetsFilterService) {}

  @Input()
  public set error(error: LibraryError) {
    this.service.patchState({ error });
  }

  @Input()
  public set repositoryId(repositoryId: string) {
    this.service.patchState({ repositoryId });
  }

  public writeValue(obj: AssetFilter): void {
    if (obj) {
      this.service.patchState({ selectedValues: [obj] });
    }
  }

  public registerOnChange(fn: any): void {
    this.service.patchState({ onChange: fn });
  }

  public registerOnTouched(fn: any): void {
    this.service.patchState({ onTouched: fn });
  }
}
