import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CelumPipesModule } from '@celum/ng2base';

import { CelumButtonModule } from '../button';
import { Banner } from './component/banner';
import { CelumIconModule } from '../icon/icon.module';

@NgModule({
  imports: [
    // basic angular modules
    CommonModule,
    TranslateModule,
    // our own modules
    CelumIconModule,
    CelumButtonModule,
    CelumPipesModule
  ],
  declarations: [Banner],
  exports: [Banner, TranslateModule]
})
export class CelumBannerModule {}
