import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { switchMap } from 'rxjs';

import {
  CelumBannerModule,
  CelumEmptyPageModule,
  CelumIconModule,
  CelumMessageModule,
  CelumSnackbarModule,
  ICON_TEXT_ALIGN,
  IconConfiguration
} from '@celum/common-components';
import { LibraryStatus } from '@celum/libraries/domain';
import { LibraryWizardApi } from '@celum/libraries/features/designer';
import { LibraryFindOptions, LibraryStorageService } from '@celum/libraries/shared';
import { StorageLimitMessageBoxComponent } from '@celum/libraries/ui';

import { DashboardInfoHeaderComponent } from './components/dashboard-info-header/dashboard-info-header.component';
import { LibraryListComponent } from './components/library-list/library-list.component';
import { LibraryListService } from './components/library-list/library-list.service';
import { DashboardService } from './dashboard.service';

export const LOCAL_STORAGE_LIBRARIES_FILTER_KEY = 'LIBRARIES_FILTER';

export interface LibraryFilter {
  titleKey: string;
  key: keyof LibraryFindOptions;
  value: string;
  displayValue?: string;
}

@Component({
  imports: [
    CommonModule,

    MatButtonModule,
    MatDialogModule,

    CelumBannerModule,
    CelumEmptyPageModule,
    CelumIconModule,
    CelumMessageModule,
    CelumSnackbarModule,

    DashboardInfoHeaderComponent,
    LibraryListComponent,
    StorageLimitMessageBoxComponent
  ],
  providers: [LibraryStorageService, LibraryListService],
  selector: 'libs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent {
  protected LibraryStatus = LibraryStatus;

  protected readonly createIcon = IconConfiguration.medium('plus-m').withTextAlign(ICON_TEXT_ALIGN.right);

  constructor(
    protected service: DashboardService,
    private libraryWizardApi: LibraryWizardApi,
    private libraryListService: LibraryListService
  ) {
    service.init();
  }

  public addNew(): void {
    this.libraryWizardApi
      .open()
      .pipe(switchMap(dialog => dialog.afterClosed()))
      .subscribe(result => {
        if (result) {
          this.service.init();
          this.libraryListService.load();
        }
      });
  }

  protected applyFilter($event: LibraryFilter[]): void {
    this.service.patchState({ filters: $event });
  }
}
