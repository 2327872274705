import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { UserService } from '@celum/authentication';
import { CelumAvatarModule, CelumIconModule, CelumInputsModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumRemainingCharactersComponent, CelumSelectBoxModule } from '@celum/internal-components';
import { LibraryUsageStatisticsService } from '@celum/libraries/shared';
import { CelumPipesModule } from '@celum/ng2base';
import { AccountUser } from '@celum/shared/domain';
import { UserAvatarComponent } from '@celum/shared/ui-people';

import { RepositorySelectorComponent } from './repository-selector/repository-selector.component';

@Component({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,

    CelumAvatarModule,
    CelumIconModule,
    CelumInputsModule,
    CelumPipesModule,
    CelumRemainingCharactersComponent,
    CelumSelectBoxModule,

    RepositorySelectorComponent,
    UserAvatarComponent
  ],
  selector: 'libs-library-details',
  templateUrl: './library-details.component.html',
  styleUrls: ['./library-details.component.scss', '../../styles/common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LibraryDetailsComponent {
  @HostBinding('class.libs-library-details')
  @Output()
  public readonly stepCompleted = new EventEmitter<boolean>();

  @Input()
  public form: FormGroup<{
    name: FormControl<string>;
    repositoryId: FormControl<string>;
  }>;

  @Input()
  public creator: AccountUser;

  protected privateUsageStatistics: { count: number; published: number };

  protected usageStatisticsLanguageKeyEnding: string;

  protected infoIconConfig = IconConfiguration.small('info-icon').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(12);
  protected experienceIconConfig = IconConfiguration.small('active-portals').withIconSize(22);

  constructor(protected userService: UserService) {}

  @Input()
  public set usageStatistics(usageStatistics: any) {
    this.privateUsageStatistics = {
      count: usageStatistics?.active + usageStatistics?.published,
      published: usageStatistics?.published
    };
    this.usageStatisticsLanguageKeyEnding = LibraryUsageStatisticsService.getLanguageKeyEnding(usageStatistics);
  }

  public focusOut(): void {
    if (this.form.controls.name.value === '') {
      this.form.controls.name.markAsDirty();
    }
  }
}
