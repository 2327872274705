import { AfterViewInit, DestroyRef, Directive, ElementRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuTrigger } from '@angular/material/menu';
import { filter, fromEvent, map, merge, switchMap, takeUntil } from 'rxjs';

import { ZIndexHelper } from '@celum/core';

/**
 * This directive helps to show/hide context menus without the need for a backdrop.
 *
 * Usage
 *
 * Specify an {@link ElementRef} or {@link HTMLElement} as target that should show the context menu on right click, e.g. by either
 * - using a template variable <div #libraryListRow></div>
 * - injecting the ElementRef for the component
 *
 * Place the directive on a {@link MatMenuTrigger}
 *
 * ```typescript
 *   <div [contextMenuHandler]="hostElement" [matMenuTriggerFor]="aMenu"></div>
 * ```
 *
 * Technically the directive listens to contextmenu events on the target element and opens the menu accordingly. If the menu is open, the directive starts
 * listening for click and contextmenu events on the document and closes the menu if the user clicks outside the target element.
 * If you want to force the context menu to open as well on `click` events, use `forceContextMenuOnLeftClick`.
 *
 * Background
 *
 * A backdrop closes a menu, but also intercepts context menu (aka right button) clicks and shows the default browser context menu. The user, however,
 * clicking on another component with a context menu, expects its context menu to be shown. This directive helps to achieve this.
 */
@Directive({
  selector: '[contextMenuHandler]',
  standalone: true
})
export class ContextMenuHandlerDirective implements AfterViewInit {
  @Input() public contextMenuHandler: ElementRef | HTMLElement;
  @Input() public forceContextMenuOnLeftClick = false;
  private menu: MatMenuTrigger = inject(MatMenuTrigger, { optional: true });

  private menuRef: ElementRef = inject(ElementRef);
  private destroyRef: DestroyRef = inject(DestroyRef);

  private zIndexHelper: ZIndexHelper;

  constructor() {
    this.menu || console.error('ContextMenuHandlerDirective: No MatMenuTrigger or MatMenuTrigger found');
  }

  public ngAfterViewInit(): void {
    this.handleContextMenuMouseEvents();
    this.handleDocumentMouseEvents();
  }

  private onDocumentContextMenu(event: MouseEvent): void {
    const node = event.target as Node;
    const clickedInside =
      'nativeElement' in this.contextMenuHandler ? this.contextMenuHandler.nativeElement.contains(node) : this.contextMenuHandler.contains(node);

    if (clickedInside) {
      const { x, y } = event;
      const menuX = x;
      const menuY = y;

      this.menuRef.nativeElement.style.left = `${menuX}px`;
      this.menuRef.nativeElement.style.top = `${menuY}px`;

      this.zIndexHelper && event.stopPropagation();

      requestAnimationFrame(() => {
        this.menu.openMenu();
        // in case the menu was already opened before we place it accordingly
        this.menu.updatePosition();

        this.zIndexHelper?.destroy();
        // the default value for our applyLayerStyles mixin is 10000, so we set the zIndex to more than that to make sure that the mat-menu
        // is on top of everything (especially above the PortalListItem hover style)
        this.zIndexHelper = new ZIndexHelper('.cdk-overlay-container', 10001);
      });
      event.preventDefault();
    } else {
      this.closeMenu();
    }
  }

  private handleContextMenuMouseEvents(): void {
    const native = 'nativeElement' in this.contextMenuHandler ? this.contextMenuHandler.nativeElement : this.contextMenuHandler;

    merge(fromEvent<MouseEvent>(native, 'click'), fromEvent<MouseEvent>(native, 'contextmenu'))
      .pipe(
        filter(event => (event.type === 'click' && this.forceContextMenuOnLeftClick) || event.type === 'contextmenu'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => this.onDocumentContextMenu(event));
  }

  private handleDocumentMouseEvents(): void {
    this.menu.menuOpened
      .pipe(
        switchMap(() => merge(fromEvent(document, 'click'), fromEvent(document, 'contextmenu')).pipe(takeUntil(this.menu.menuClosed))),
        map(event => event as MouseEvent),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => (event.type === 'click' ? this.closeMenu() : this.onDocumentContextMenu(event)));
  }

  private closeMenu(): void {
    this.zIndexHelper?.destroy();
    this.zIndexHelper = null;
    this.menu.closeMenu();
  }
}
