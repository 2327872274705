import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentStore } from '@ngrx/component-store';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { LibraryVariant } from '@celum/libraries/domain';
import { CelumDirectivesModule, TranslationModule } from '@celum/ng2base';

import { VariantsFilterComponent } from './variants-filter/variants-filter.component';
import { VariantsListComponent } from './variants-list/variants-list.component';

export interface VariantStepState {
  selectedVariants: LibraryVariant[];
  loadingVariants: boolean;
}

export interface VariantStepViewModel {
  selectedVariants: LibraryVariant[];
  loadingVariants: boolean;
}

@Component({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,

    MatMenuModule,
    MatTooltipModule,

    CelumDirectivesModule,
    CelumIconModule,
    TranslationModule,

    VariantsFilterComponent,
    VariantsListComponent
  ],
  selector: 'libs-variants-step',
  templateUrl: './variants-step.component.html',
  styleUrls: ['./variants-step.component.scss', '../../styles/common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class VariantsStepComponent extends ComponentStore<VariantStepState> implements OnInit {
  @HostBinding('class.libs-variants-step') public hostCls = true;

  @Input() public form: FormGroup<{
    variants: FormControl<LibraryVariant[]>;
  }>;

  public vm$ = this.select(state => VariantsStepComponent.createViewModel(state));

  protected readonly infoIcon = IconConfiguration.small('info-icon').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(12);

  constructor() {
    super({
      selectedVariants: [],
      loadingVariants: false
    });
  }

  public ngOnInit(): void {
    this.patchState({ selectedVariants: this.form.controls.variants.value || [] });
    this.form.controls.variants.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.patchState({ selectedVariants: value || [] });
    });
  }

  public removeItem(item: LibraryVariant): void {
    this.patchState({ selectedVariants: this.get().selectedVariants.filter(variant => variant.id !== item.id) });
    this.form.patchValue({ variants: this.get().selectedVariants });
  }

  public variantsChanged(variants: LibraryVariant[]): void {
    this.form.patchValue({ variants });
  }

  private static createViewModel(state: VariantStepState): VariantStepViewModel {
    return {
      selectedVariants: state.selectedVariants,
      loadingVariants: state.loadingVariants
    };
  }
}
