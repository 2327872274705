import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumEmptyPageModule, CelumIconModule, CelumLoadingMaskModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { AssetMetadataField, LibraryError } from '@celum/libraries/domain';
import { CelumDirectivesModule, CelumPipesModule, TranslationModule } from '@celum/ng2base';
import { SearchAndSelectComponent } from '@celum/shared/ui';

import { MetadataFilterService } from './metadata-filter.service';

@Component({
  imports: [
    CommonModule,
    OverlayModule,
    TranslateModule,

    MatMenuModule,
    MatTooltipModule,

    CelumDirectivesModule,
    CelumEmptyPageModule,
    CelumIconModule,
    CelumLoadingMaskModule,
    CelumPipesModule,
    TranslationModule,

    SearchAndSelectComponent
  ],
  selector: 'libs-metadata-filter',
  templateUrl: './metadata-filter.component.html',
  styleUrls: ['./metadata-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    MetadataFilterService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MetadataFilterComponent,
      multi: true
    }
  ]
})
export class MetadataFilterComponent implements ControlValueAccessor {
  @HostBinding('class.libs-metadata-filter') public hostCls = true;

  @Input() public error: LibraryError;

  protected addNewMetadata = IconConfiguration.large(`add-metadata`)
    .withIconSize(32)
    .withColor(ColorConstants.BLUE_GRAY_600)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);

  constructor(protected service: MetadataFilterService) {}

  @Input()
  public set repositoryId(repositoryId: string) {
    this.service.patchState({ repositoryId });
  }

  public writeValue(obj: AssetMetadataField[]): void {
    this.service.patchState({ selectedValues: obj });
  }

  public registerOnChange(fn: any): void {
    this.service.patchState({ onChange: fn });
  }

  public registerOnTouched(fn: any): void {
    this.service.patchState({ onTouched: fn });
  }
}
